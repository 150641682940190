import { image } from "@/store/model";
import { default_image } from "@/core/config/AppConfig";
import { date } from "yup";

export interface News {
  _id?: string;
  name: string;
  slug: string;
  short_content: string;
  content: string;
  thumbnail: image;
  categories: string;
  status: string;
  publish_by: string;
  position_career?: string;
  location_career?: string;
  address_work?: string,
  phone_contact?: string,
  email_contact?:  string;
  data_info?:{
    experience?: string
    probationary_period?: string
    salary?: string
    level?: string
    working_time?: string
    vacancy?: string
    number?: number
    age?: string
    sex?: string
    qualification?: string
    expiry_date?: string
  }
  seo: {
    robot: string;
    title: string;
    keywords: string;
    description: string;
  };
}
export const NewsDefault: News = {
  _id: "",
  name: "",
  slug: "",
  short_content: "",
  content: "",
  thumbnail: default_image,
  categories: "",
  status: "active",
  publish_by: "",
  data_info:{expiry_date:''},
  seo: {
    robot: "index, follow",
    title: "",
    keywords: "",
    description: "",
  },
};
